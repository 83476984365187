import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import DriverTracking from './DriverTracking';
import Intransit from './Intransit';
import MapMarker from './MapMarker';
import LocateAdd from './LocateAddress';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'devextreme/dist/css/dx.light.css';
import GuestGpsMap from './GuestGpsMap';
import Home from './Home';
import Kioskmaps from './Kioskmaps';

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/:UserId/:ComanypId/:UserName' element={< Home />}></Route>
        <Route path='/DriverTracking/:UserId/:CompanyId/:UserName' element={< DriverTracking />}></Route>
        <Route path='/Intransit/:PartyId/:DriverId/:CompanyId' element={< Intransit />}></Route>
        <Route path='/MapMarker' element={< MapMarker />}></Route>
        <Route path='/LocateAddress/:CompanyId/:locationIds' element={< LocateAdd />}></Route>
        <Route path='/Guestmap/:qs' element={<GuestGpsMap />}></Route>
        <Route path='/Kioskmaps/:code/:companyId/:userName' element={< Kioskmaps />}></Route>
      </Routes>
    </Router>
  );
}

export default App;
