import React, { useEffect } from 'react';
import { useState } from 'react';
import { DateBox, SelectBox } from 'devextreme-react';
import { NavLink } from 'react-bootstrap';
import { useNavigate, useParams } from "react-router-dom";
import axios from 'axios';

const DriverTracking = () => {

  let { UserId, CompanyId, UserName } = useParams();
  localStorage.setItem("userId", UserId);
  localStorage.setItem("userName", UserName);
  localStorage.setItem("companyId", CompanyId);

  const navigate = useNavigate();

  const navigateToGmap = () => {
    navigate(`/${UserId}/${CompanyId}/${UserName}`);
    window.location.reload();
  };


  const RefreshMap = () => {
    const currDate = new Date();
    localStorage.setItem("selDate", currDate);
    window.location.reload();
  };

  // const [isCheckedDriver, setIsCheckeddriver] = useState(true);
  // const [isCheckedDispatch, setIsCheckedDispatch] = useState(true);
  // const [isCheckedReser, setIsCheckedReser] = useState(true);
  // const [isCheckedPend, setIsCheckedPend] = useState(true);
  const [userList, setUserList] = useState([]);
  const [selUserId, setSelUserId] = useState(parseInt(UserId, 10)); // To store the selected username
  const [fromDate, setFromDate] = useState(localStorage.getItem("selDate") || new Date());


  useEffect(() => {

    axios.post(`https://services.bookashuttle.net/api/UserManagement/GetUsersByStatus?companyId=${CompanyId}&status=1`)
      .then((data) => {
        console.log(data.data.data)
        setUserList(data.data.data);
      })
      .catch((error) => console.log(error));
  }, []);

  const OnDriverChange = (e) => {

    setSelUserId(e.value); // Set the selected username in the state // Set the selected username in the state
    if (e.value !== null) {
      const user = userList.find(i => i.userId === e.value)
      if (user.userId === e.value) {
        localStorage.setItem("userId", user.userId);
        localStorage.setItem("userName", user.userName);
        localStorage.setItem("companyId", user.companyId);
        window.location.href = "/DriverTracking/" + user.userId + "/" + user.companyId + "/" + user.userName;
      }
    }
  };


  return (
    <div>
      {/* <input type="checkbox" id="chkAvilable" readOnly checked={isCheckedDriver} className='mb-4' /><span className='ms-3 text-success'>Available Drivers</span>
        <button id="rotateCar">Refresh</button>
        <input type="checkbox" id="chkUnAvilable" readOnly checked={isCheckedDispatch} className='ms-4' /><span className='ms-3 text-secondary'>Dispatch</span>
        <input type="checkbox" id="chkUnAvilable" readOnly checked={isCheckedReser} className='ms-4' /><span className='ms-3 text-info'>Reservation</span>
        <input type="checkbox" id="chkUnAvilable" readOnly checked={isCheckedPend} className='ms-4'/><span className='ms-3 text-danger'>Pending   </span>   */}
      <p className='tacking_name text-center alert alert-success text-dark p-2 mb-0'>
        {UserName}
      </p>
      <div className='row alert alert-success p-3 m-0'>
        <div className='col-lg-3 d-flex'>
          <NavLink className='text-primary ms-2' onClick={RefreshMap}><img src="/refresh.png" width={20} alt=''></img>Refresh</NavLink>
          <NavLink className='text-primary ms-2' onClick={navigateToGmap}><img src="/user.png" alt=''></img>All Drivers</NavLink>
          {/* <Button variant="primary" onClick={navigateToGmap}  className='ms-4'>Drivers Tracking 2</Button> */}
        </div>
        <div className='col-lg-6'>
          <div className='col-lg-4 mx-auto'>
            <SelectBox id='selectdriver'
              dataSource={userList}
              valueExpr="userId"
              displayExpr="userName"
              searchEnabled
              showClearButton
              value={selUserId}
              onValueChanged={OnDriverChange}
            />
          </div>
        </div>
        <div className='col-lg-3'>
          <div className='col-lg-8 float-end '>
            <DateBox
              displayFormat="longdate"
              applyValueMode="instantly"
              openOnFieldClick
              value={fromDate}
              min={new Date(0)} // Set the minimum date to a very early date (e.g., January 1, 1970)
              max={new Date()} // Set the maximum date to the current date
              onValueChanged={(e) => {
                const selectedStartDate = e.value;
                setFromDate(selectedStartDate);
                localStorage.setItem("selDate", selectedStartDate);
                window.location.reload();
              }}
            />
          </div>
        </div>
        {/* <div>
                <p>Selected Username: {selectedUser}</p>
            </div> */}
      </div>

      <div id="dvCanvas" style={{ "width": "100vw", "height": "100vh", "margin-left": "-12px" }}></div>
    </div>
  )
}

export default DriverTracking



// import React, { useEffect } from 'react';
// import { useState } from 'react';
// import { DateBox, SelectBox } from 'devextreme-react';
// import { NavLink, Form } from 'react-bootstrap';
// import { useNavigate, useParams } from "react-router-dom";
// import axios from 'axios';

// const DriverTracking = () => {
//   const navigate = useNavigate();


//   const navigateToGmap = () => {
//     // 👇️ navigate to /contacts
//     navigate('/');
//     window.location.reload();
//   };


//   const RefreshMap = () => {
//     const currDate = new Date();
//     localStorage.setItem("selDate", currDate);
//     window.location.reload();
//   };

//   // const [isCheckedDriver, setIsCheckeddriver] = useState(true);
//   // const [isCheckedDispatch, setIsCheckedDispatch] = useState(true);
//   // const [isCheckedReser, setIsCheckedReser] = useState(true);
//   // const [isCheckedPend, setIsCheckedPend] = useState(true);
//   const [users, setUsers] = useState([]);

//   const [fromDate, setFromDate] = useState(new Date());
//   let { CompanyId, UserName } = useParams();
//   const [selectedUser, setSelectedUser] = useState(""); // To store the selected username


//   useEffect(() => {

//     axios.post(`https://services.bookashuttle.net/api/UserManagement/GetUsersByStatus?companyId=${CompanyId}&status=1`)
//       .then((data) => {
//         console.log(data)
//         const extractedUsernames = data.data.map((user) => user.userName);
//         setUsers(extractedUsernames); // Update your component state with the extracted usernames
//       })
//       .catch((error) => {
//         console.error('Error:', error);
//       });
//   }, []);

//   const OnDriverChange = (e) => {

//     const newSelectedUser = e.value; // Get the new selected username
//     setSelectedUser(newSelectedUser); // Set the selected username in the state // Set the selected username in the state
//     users.map((user) => {
//       if (user.userName === newSelectedUser) {
//         localStorage.setItem("userId", user.userId);
//         localStorage.setItem("userName", user.userName);
//         localStorage.setItem("companyId", user.companyId);
//         window.location.href = "/DriverTracking/" + user.userId + "/" + user.companyId + "/" + user.userName;
//       }
//     })
//   };


//   return (
//     <div>
//       {/* <input type="checkbox" id="chkAvilable" readOnly checked={isCheckedDriver} className='mb-4' /><span className='ms-3 text-success'>Available Drivers</span>
//         <button id="rotateCar">Refresh</button>
//         <input type="checkbox" id="chkUnAvilable" readOnly checked={isCheckedDispatch} className='ms-4' /><span className='ms-3 text-secondary'>Dispatch</span>
//         <input type="checkbox" id="chkUnAvilable" readOnly checked={isCheckedReser} className='ms-4' /><span className='ms-3 text-info'>Reservation</span>
//         <input type="checkbox" id="chkUnAvilable" readOnly checked={isCheckedPend} className='ms-4'/><span className='ms-3 text-danger'>Pending   </span>   */}




//       <p className='tacking_name text-center alert alert-success text-dark p-2 mb-0'>
//         {UserName}
//       </p>


//       <div className='row alert alert-success p-3 m-0'>
//         <div className='col-lg-3 d-flex'>
//           <NavLink className='text-primary ms-2' onClick={RefreshMap}><img src="/refresh.png" width={20}></img>Refresh</NavLink>
//           <NavLink className='text-primary ms-2' onClick={navigateToGmap}><img src="/user.png"></img>All Drivers</NavLink>
//           {/* <Button variant="primary" onClick={navigateToGmap}  className='ms-4'>Drivers Tracking 2</Button> */}
//         </div>
//         <div className='col-lg-6'>
//           <div className='col-lg-4 mx-auto'>

//             <SelectBox id='selectdriver'
//               dataSource={users}
//               value={UserName}
//               searchEnabled
//               showClearButton
//               onValueChanged={OnDriverChange}
//             >
//             </SelectBox>
//           </div>

//         </div>
//         <div className='col-lg-3'>
//           <div className='col-lg-8 float-end '>
//             <DateBox
//               displayFormat="longdate"
//               applyValueMode="instantly"
//               openOnFieldClick
//               value={localStorage.getItem("selDate")}
//               min={new Date(0)} // Set the minimum date to a very early date (e.g., January 1, 1970)
//               max={new Date()} // Set the maximum date to the current date
//               onValueChanged={(e) => {
//                 const selectedStartDate = e.value;
//                 setFromDate(selectedStartDate);
//                 localStorage.setItem("selDate", selectedStartDate);
//                 window.location.reload();
//               }}
//             />
//           </div>

//         </div>
//         {/* <div>
//                 <p>Selected Username: {selectedUser}</p>
//             </div> */}
//       </div>

//       <div id="dvCanvas" style={{ "width": "100vw", "height": "100vh", "margin-left": "-12px" }}></div>
//     </div>

//   )
// }

// export default DriverTracking
