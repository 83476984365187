import React from 'react'



const MapMarker = () => {
   
  return (
      
      <div id="dvCanvas" style={{"width":"100vw","height":"100vh"}}>
      </div>

  )
}

export default MapMarker