import React from 'react'


const Kioskmaps = () => {

  const notes = localStorage.getItem("note");
  const buttonText = localStorage.getItem("rText");
  return (
    <div>
      <div style={{ position: 'fixed', top: '0', width: '100%', backgroundColor: "#fff", zIndex: '3' }} >
        <div className='ps-2 pt-1 pb-0'>
          <button id='btnRedirect' type='button' className='btn bg-primary text-white rounded map_btns ms-3' >{buttonText} </button>

          <div
            dangerouslySetInnerHTML={{ __html: notes }}
          />
        </div>
      </div>
      <div className="col-lg-12" id="dvCanvas" style={{ "width": "100vw", "height": "93vh", "top": "66px" }} >
      </div>
    </div>
  )
}

export default Kioskmaps
