import React from "react";
import Gmap from "./Gmap";
import { useParams } from "react-router-dom";

 const Home = () => {
    const { UserId, CompanyId,UserName } = useParams  ();

    const data = {
      UserId:UserId,
      CompanyId:CompanyId,
      UserName:UserName
    };

    return (

        <>
        <Gmap />
        </>
    );
  
 }

 export default Home