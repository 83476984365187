import React from 'react'

const GuestGpsMap = () => {

  
  // useEffect(() => {
  //   // Define the single query string value
  //   // const value = 'exampleValue';

  //   // // Construct the query string
  //   // const queryString = `paramName=${encodeURIComponent(value)}`;

  //   // // Navigate to the /DriverTracking route with the query string
  //   // const newTabUrl = `/Single?${queryString}`;
  //   // window.open(newTabUrl, '_blank');
  // }, []);



  return (
    <div id="dvCanvas" style={{"width":"100vw","height":"100vh"}}>
    </div>
  )
}

export default GuestGpsMap