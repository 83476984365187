import React from 'react';

const LocateAdd = () => {
  return (
    <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
      <div id="mapHeadCont" style={{ position: 'fixed', top: '0', width: '100%', backgroundColor: "#fff", zIndex: '3', padding: '3px' }}>
      </div>

      <div id="dvCanvas" style={{ "width": "100vw", "height": "100vh" }}>
      </div>
    </div>
  )
}
export default LocateAdd